import { AppDispatch } from "../store/store";
import { Post } from "../utils/apiServices";

interface IUploadJdTemplateResponse {
  status_message: string;
  data: UploadJdTemplateData[];
}
interface UploadJdTemplateData {
  bg_task_id: number;
  total_files: number;
  background_status: string;
}

const uploadJdTemplateEndpoint = "job-descriptions/templates";

export const uploadJdTemplatesThunk = (payload: any) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await Post<IUploadJdTemplateResponse, any>(
        uploadJdTemplateEndpoint,
        payload.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
};
