/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import DetailsCard from "./Components/DetailsCard";
import { Stack } from "@mui/system";
import { ThemeProvider } from "@emotion/react";
import theme from "../../widgets/Theme/theme";
import DashBoardTable from "./Components/Table";
import { AssistoButton } from "../../widgets/AssitoButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useDispatch, useSelector } from "react-redux";
import { loginInfoData } from "../../reducers/userInfoReducer";
import { AppDispatch } from "../../store/store";
import { DragEvent, useEffect, useRef, useState } from "react";
import AssistoModal from "../../widgets/Modal";
import { CSSProperties } from "styled-components";
import DragDropComponent from "./Components/DragAndDropFiles";
import { MAX_LIMIT_FOR_UPLOAD_RESUME } from "../../utils/constants";
import { callUploadResumes } from "../../thunks/uploadResumesThunk";
import JSZip from "jszip";
import { callUploadResumeCount } from "../../thunks/totalResumesCountThunk";
import { getCardDetails } from "../../reducers/totalResumesCountReducer";
import ExtractnStatusCard from "../../widgets/ExtractnStatusCard";
import {
  TotalNumberOfFilesUploaded,
  getUploadApiStatus,
} from "../../reducers/uploadResumesReducer";
import {
  getProcessedData,
  getResumeExtractionStatus,
  resetUploadProgressStatusState,
} from "../../reducers/uploadProgressStatusReducer";
import { resetUploadFilesState } from "../../reducers/uploadResumesReducer";
import { createJdPage } from "../Routes/routes";
import { useNavigate } from "react-router-dom";
import { isDefined } from "../../utils/helper";
import { loggedInUserPermissions } from "../../utils/permissionManagement";
import { StatuscardType } from "../../utils/commonEnums";
import { Column } from "../../widgets/Table";
import { ButtonVarient } from "../../utils/commonEnums";
import { ReactComponent as ManualCreationIcon } from "../../assets/images/manual-creation.svg";
import { ReactComponent as CreateWithTemplateIcon } from "../../assets/images/create-from-jd-template.svg";
import { IJdtemplateslist, ISavedTemplateData, jdSavedAsTemplate } from "../../thunks/homeThunk";
import { jdSavedAsTemplateData } from "../../reducers/homeReducer";
import { Item } from "../../utils/commonInterfaces";

const uploadModalStyle: CSSProperties = {
  position: "absolute",
  transform: "translate(50%, -50%)",
  top: "50%",
  right: "50%",
  width: 800,
  maxHeight: 600,
  borderRadius: "8px",
  backgroundColor: "#fff",
  padding: 3,
};

export interface ITableData {
  title: string;
  tableColumn: Column[];
  tableRows: any[];
  defaultMessage: string;
  headerFontSize: string;
}

const DashBoard = () => {
  const { t } = useTranslation(NAMESPACE.HOME);
  const dispatch: AppDispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  type FileSizeMap = Record<string, number>;

  const [modalState, setModalState] = useState(false);
  const [modalType, setModalType] = useState<
    "uploadResume" | "createJd" | null
  >(null);
  const [createWithTemplateModal, setCreateWithTemplateModal] = useState(false);
  const [validFiles, setValidFiles] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [fileSizes, setFileSizes] = useState<FileSizeMap>({});
  const [dragActive, setDragActive] = useState(false);
  const [onProgress, setOnProgress] = useState(0);

  const { userInfo } = useSelector(loginInfoData);
  const cardDetails = useSelector(getCardDetails);
  const totalNumberOfFilesUploaded = useSelector(TotalNumberOfFilesUploaded);
  const jdTemplateData: ISavedTemplateData = useSelector(
    jdSavedAsTemplateData
  );
  const [selectJdTemplate, setSelectJdTemplate] =
    useState<string | null>(null);
  const processedData = useSelector(getProcessedData);
  const getResumeUploadStatus = useSelector(getUploadApiStatus);
  const getResumeExtractnStatus = useSelector(getResumeExtractionStatus);
  const isExtractnDone =
    processedData?.data?.total_processed_files === totalNumberOfFilesUploaded;
  const navigate = useNavigate();
  const JdColumns: Column[] = [
    { id: "jobId", label: "Job Id", minWidth: 70 },
    { id: "JobTitle", label: "Job Title", minWidth: 100 },
    {
      id: "JobLocation",
      label: "Job Location",
      minWidth: 120,
    },
    {
      id: "Experience",
      label: "Experience",
    },
    {
      id: "MustHaveSkills",
      label: "Must have skills",
      minWidth: 150,
    },
    {
      id: "NiceToHaveSkills",
      label: "Nice to have skills",
      minWidth: 150,
    },
    {
      id: "Vacancy",
      label: "Vacancy",
      minWidth: 150,
    },
  ];
  const applicantColumns: Column[] = [
    { id: "Id", label: "Id", minWidth: 40 },
    { id: "ApplicantName", label: "Applicant Name", minWidth: 140 },
    {
      id: "JobLocation",
      label: "Location",
      minWidth: 120,
    },
    {
      id: "Experience",
      label: "Experience",
    },
    {
      id: "MustHaveSkills",
      label: "Must have skills",
      minWidth: 150,
    },
    {
      id: "NiceToHaveSkills",
      label: "Nice to have skills",
      minWidth: 150,
    },
    {
      id: "Vacancy",
      label: "Vacancy",
      minWidth: 150,
    },
  ];

  const tableData: ITableData[] = [
    {
      title: "HOME_PAGE.JD_TABLE_TITLE",
      tableColumn: JdColumns,
      tableRows: [],
      headerFontSize: "12px",
      defaultMessage: "HOME_PAGE.PLEASE_CREATE_JD",
    },
    {
      title: "HOME_PAGE.APPLICANT_TABLE_TITLE",
      tableColumn: applicantColumns,
      tableRows: [],
      headerFontSize: "12px",
      defaultMessage: "HOME_PAGE.PLEASE_UPLOAD_RESUMES",
    },
  ];
  const handleUploadFileChange = (files: File[]) => {
    if (!files) return;
    const allowedExtensions = ["pdf", "doc", "docx", "zip"];
    const maxFileSize = 5242880; // 5MB
    const maxZipFileSize = 524288000; // 500MB

    let error = "";
    let duplicateFile = false;
    let newValidFilesArray: File[] = [];
    let newFileSizes: FileSizeMap = {};

    const selectedFileNames = new Set<string>(
      validFiles.map((file) => file.name)
    );

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
        error =
          t("HOME_PAGE.UPLOAD_MODAL.INVALID_FILE_FORMAT") + ":" + file.name;
        break;
      }

      if (fileExtension === "zip" && file.size > maxZipFileSize) {
        error =
          t("HOME_PAGE.UPLOAD_MODAL.ZIP_FILE_SIZE_EXCEEDS_500MB_LIMIT") +
          ":" +
          file.name;
        break;
      }

      if (fileExtension !== "zip" && file.size > maxFileSize) {
        error =
          t("HOME_PAGE.UPLOAD_MODAL.FILE_SIZE_EXCEEDS_5MB_LIMIT") +
          ":" +
          file.name;
        break;
      }

      newFileSizes[file.name] = file.size;
      newValidFilesArray.push(file);
    }

    newValidFilesArray = newValidFilesArray.filter((file) => {
      if (selectedFileNames.has(file.name)) {
        duplicateFile = true;
      }
      return !selectedFileNames.has(file.name);
    });

    if (
      newValidFilesArray.length + validFiles.length >
      MAX_LIMIT_FOR_UPLOAD_RESUME
    ) {
      error = t("HOME_PAGE.UPLOAD_MODAL.FILE_LIMIT_EXCEEDS");
    }

    if (error) {
      setErrorMessage(error);
    } else {
      const mergedValidFiles = [...validFiles, ...newValidFilesArray];
      const mergedFileSizes = { ...fileSizes, ...newFileSizes };
      setValidFiles(mergedValidFiles);
      setFileSizes(mergedFileSizes);
      setErrorMessage(
        duplicateFile
          ? t("HOME_PAGE.UPLOAD_MODAL.DUPLICATE_FILE_ARE_IGNORED")
          : ""
      );
    }
  };

  const handleDrag = (
    e: DragEvent<HTMLDivElement> | DragEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files !== null) {
      const filesArray = Array.from(e.dataTransfer.files);
      handleUploadFileChange(filesArray);
    }
  };

  const handleRemoveFile = (index: number) => {
    const filesCopy = [...validFiles].slice().reverse();
    filesCopy.splice(index, 1);
    setValidFiles(filesCopy);
  };

  const handleUploadResumes = () => {
    setModalState(true);
    setModalType("uploadResume");
  };

  const handleCreateJd = () => {
    setModalState(true);
    setModalType("createJd");
  };

  const handleModalClose = () => {
    setModalState(false);
    setModalType(null);
    setCreateWithTemplateModal(false);
    setValidFiles([]);
  };

  const handleFileUpload = async () => {
    try {
      if (validFiles.length > 0) {
        const zip = new JSZip();
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${(
          currentDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${currentDate
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        const formattedTime = `${currentDate
          .getHours()
          .toString()
          .padStart(2, "0")}-${currentDate
          .getMinutes()
          .toString()
          .padStart(2, "0")}-${currentDate
          .getSeconds()
          .toString()
          .padStart(2, "0")}`;
        const zipFileName = `files_${formattedDate}_${formattedTime}.zip`;
        // Add each file to the zip
        validFiles.forEach((file, index) => {
          zip.file(file.name, file);
        });

        const zipBlob = await zip.generateAsync({ type: "blob" });
        const formData = new FormData();
        formData.append("file", zipBlob, zipFileName);
        handleModalClose();

        await dispatch(
          callUploadResumes({
            formData,
            progress: (event: any) => {
              setOnProgress(event.progress * 100);
            },
          })
        );
      }
    } catch (error) {
      console.error("Error occurred during file upload:", error);
    }
  };

  useEffect(() => {
    if (getResumeUploadStatus === "failed") {
      setModalState(false);
      dispatch(resetUploadFilesState());
    }
    if (getResumeExtractnStatus === "failed") {
      localStorage.removeItem("job_status_id");
    }
  }, [getResumeUploadStatus, getResumeExtractionStatus]);

  useEffect(() => {
    dispatch(callUploadResumeCount());
    localStorage.removeItem("job_status_id");
  }, []);

  useEffect(() => {
    if (getResumeUploadStatus === "pending" || !isExtractnDone) {
      window.onbeforeunload = () =>
        "If you leave this page, you'll also leave the call";
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [getResumeUploadStatus, isExtractnDone]);

  useEffect(() => {
    if (onProgress === 100) {
      setModalState(false);
    }
  }, [onProgress]);

  const getStatusCardMessage = () => {
    if (isExtractnDone) {
      return t("HOME_PAGE.UPLOAD_MODAL.FILES_UPLOADED_SUCCESSFULLY");
    } else if (!isExtractnDone) {
      return t("HOME_PAGE.UPLOAD_MODAL.THIS_CAN_TAKE_UP_TO_A_MINUTE");
    }
    if (getResumeExtractnStatus === "failed") {
      return t("HOME_PAGE.UPLOAD_MODAL.SORRY_CANT_UPLOAD_FILES");
    }
  };

  const getStatusCardType = () => {
    if (isExtractnDone) {
      return StatuscardType.Success;
    } else if (!isExtractnDone) {
      return StatuscardType.Inprogress;
    }
    if (getResumeExtractnStatus === "failed") {
      return StatuscardType.Error;
    }
  };

  const handleCreateWithTemplateModal = () => {
    setCreateWithTemplateModal(true);
    dispatch(jdSavedAsTemplate);
  };

  const handleSelectChange = (e: SelectChangeEvent<string | null>) => {
        /*
     * handleSelectChange : Manages changes to select fields. It updates the state with the selected item and,
     * if applicable, dispatches actions to update related data (e.g., cities based on selected states).
     */
    const selectedTemplateName = e.target.value as string;
    const selectedTemplate = jdTemplateData.jd_templates_list
      .flatMap((templateList: IJdtemplateslist) => templateList.jd_template_details)
      .find(templateDetails => templateDetails.template_name === selectedTemplateName);
  
    if (selectedTemplate) {
      setSelectJdTemplate(selectedTemplateName);
    }
  };


  return (
    <ThemeProvider theme={theme}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
        mt={5}
      >
        <div>
          <Typography variant="h3">
            {t("HOME_PAGE.HELLO")} {userInfo && userInfo.username}
          </Typography>
          <Typography variant="subtitle2" mt={1}>
            {t("HOME_PAGE.WELCOME_HIRE_ASSISTO")}
          </Typography>
        </div>
        <ExtractnStatusCard
          closeButtonNeeded={isExtractnDone}
          showStatusCard={getResumeExtractnStatus !== ""}
          message={getStatusCardMessage()!}
          type={getStatusCardType()!}
          onCloseStatusCard={() => {
            dispatch(resetUploadProgressStatusState());
          }}
        />
        {/* <AvatarChips color={StatusColor.ACTIVE} /> */}
        <div className="d-flex" data-testid="admin-privileges">
          {isDefined(loggedInUserPermissions) &&
            loggedInUserPermissions.includes("UPLOAD_RESUMES") && (
              <Stack mr={3}>
                <AssistoButton
                  isDisable={
                    getResumeUploadStatus === "pending" || !isExtractnDone
                  }
                  buttonVarient={ButtonVarient.Contained}
                  name={t("HOME_PAGE.UPLOAD_RESUMES")}
                  endIcon={<FileUploadOutlinedIcon />}
                  handleBtnClick={handleUploadResumes}
                  dataTestId="upload-resume-btn"
                />
              </Stack>
            )}

          {isDefined(loggedInUserPermissions) &&
            loggedInUserPermissions.includes("CREATE_JOBS") && (
              <Stack mr={4}>
                <AssistoButton
                  buttonVarient={ButtonVarient.Contained}
                  name={t("HOME_PAGE.CREATE_NEW_JD")}
                  endIcon={<AddCircleOutlineIcon />}
                  handleBtnClick={handleCreateJd}
                  dataTestId="create-jd-button"
                />
              </Stack>
            )}
        </div>
      </Stack>

      <DetailsCard cardDetails={cardDetails} />

      <DashBoardTable tableData={tableData} />
      <AssistoModal
        open={modalState}
        title={
          modalType === "uploadResume"
            ? validFiles.length === 0
              ? t("HOME_PAGE.UPLOAD_FILES")
              : ""
            : modalType === "createJd"
            ? createWithTemplateModal
              ? t("Create JD With Template")
              : t("HOME_PAGE.NEW_JOB_POSTING")
            : ""
        }
        isCloseButtonNeeded={
          modalType === "createJd"
            ? true
            : validFiles.length === 0
            ? true
            : false
        }
        handleClose={handleModalClose}
        style={uploadModalStyle}
      >
        {modalType === "uploadResume" && (
          <DragDropComponent
            handleUploadFileChange={handleUploadFileChange}
            inputRef={inputRef}
            handleDrop={handleDrop}
            handleDrag={handleDrag}
            dragActive={dragActive}
            errorMessage={errorMessage}
            fileCardData={validFiles}
            handleCancel={handleModalClose}
            handleRemoveFile={handleRemoveFile}
            handleFileUpload={handleFileUpload}
            onProgress={onProgress}
          />
        )}

        {modalType === "createJd" &&
          (!createWithTemplateModal ? (
            <Grid
              container
              marginY={"75px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                direction={"row"}
                gap={4}
              >
                <Stack
                  onClick={() => navigate(createJdPage)}
                  justifyContent={"center"}
                  alignItems={"center"}
                  textAlign={"center"}
                  width={"200px"}
                  height={"200px"}
                  bgcolor={"white"}
                  borderRadius={"8px"}
                  gap={2}
                >
                  <ManualCreationIcon />
                  <div>{t("HOME_PAGE.MANUAL_CREATION")}</div>
                </Stack>

                <Stack
                  onClick={handleCreateWithTemplateModal}
                  justifyContent={"center"}
                  alignItems={"center"}
                  textAlign={"center"}
                  width={"200px"}
                  height={"200px"}
                  bgcolor={"white"}
                  borderRadius={"8px"}
                  gap={2}
                >
                  <CreateWithTemplateIcon />
                  <div>{t("HOME_PAGE.CREATE_FROM_JD_TEMPLATE")}</div>
                </Stack>
              </Stack>
            </Grid>
          ) : (
            <>
              <Grid
                marginY={"75px"}
                container
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item xl={6} lg={6} md={6} sm={12}>
                  <FormControl fullWidth>
                    <InputLabel>{t("HOME_PAGE.SELECT_TEMPLATE")}</InputLabel>
                    <Select
                      id="selectJdTemplate"
                      value={selectJdTemplate}
                      label={t("HOME_PAGE.SELECT_TEMPLATE")}
                      onChange={handleSelectChange}
                    >
                      {jdTemplateData.jd_templates_list.map((templateList: IJdtemplateslist) =>
                          <MenuItem
                            key={templateList.jd_template_details.id}
                            value={
                              templateList.jd_template_details.template_name
                            }
                          >
                            {templateList.jd_template_details.template_name}
                          </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Stack justifyContent={"center"} direction={"row"} gap={2}>
                <AssistoButton
                  buttonVarient={ButtonVarient.Contained}
                  name="Create"
                  handleBtnClick={() => {}}
                />
                <AssistoButton
                  buttonVarient={ButtonVarient.Outlined}
                  name="Cancel"
                  handleBtnClick={() => {
                    setSelectJdTemplate(null);
                    setCreateWithTemplateModal(false);
                  }}
                />
              </Stack>
            </>
          ))}
      </AssistoModal>
    </ThemeProvider>
  );
};
export default DashBoard;
