import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import { ReactElement, ReactNode } from "react";

export interface TabInfo {
  label: string;
  icon?: ReactElement;
  content: ReactNode;
  activeImage?: string;
  inactiveImage?: string;
}

interface ITabsProps {
  tabs: TabInfo[];
}

interface ITabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: ITabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const AssistoTabs = (props: ITabsProps) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          {props.tabs.map((tab, index) => (
            <Tab
              key={index}
              sx={{
                textTransform: "none",
                minWidth: "220px",
                px: 0,
                py: 2.5,
                alignItems: "end",
                minHeight: 0,
              }}
              icon={
                tab.activeImage || tab.inactiveImage ? (
                  <img
                    src={value === index ? tab.activeImage : tab.inactiveImage}
                    alt={tab.label}
                    style={{ width: "24px", height: "24px" }} // Adjust width and height as needed
                  />
                ) : undefined
              }
              iconPosition="start"
              label={tab.label}
            />
          ))}
        </Tabs>
      </Box>
      <Stack>
        {props.tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.content}
          </CustomTabPanel>
        ))}
      </Stack>
    </Box>
  );
};

export default AssistoTabs;
