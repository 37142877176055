import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { ICategoryResponse, ICityInfoResponse, IClientInfoResponse, IEducationDegreeResponse, IIndustryResponse, IRolesResponse, ISkillResponse, ISpecializationResponse, IJobStatusUpdateResponse, IJdSavedAsTemplateResponse } from "../thunks/homeThunk";
import { ILocationInfoResponse } from "../thunks/homeThunk";
import { ICurrencyResponse } from "../thunks/homeThunk";
import { IStateInfoResponse } from "../thunks/homeThunk";

/*
 * purpose: This file defines a Redux slice for managing the state of the Home Module in the application.
 * It utilizes Redux Toolkit's `createSlice` to create a reducer with initial state and actions 
 * for handling various data related to industries, categories, roles, location information 
 * (country, state, city), currencies, education degrees, specializations, skills, and job status updates.
 * 
 * The initial state is set with default values for each property. Actions are provided to update 
 * the state in response to API calls. Selectors are also exported for accessing specific pieces 
 * of the state from the Redux store.
 * author: Aayush Chourasia
 * version: 1.0
 * lastUpdatedAt: 28-10-2024
 */

export interface IHomeModuleProps {
  industryInfo: IIndustryResponse;
  categoryInfo: ICategoryResponse;
  rolesInfo: IRolesResponse;
  countryInfo: ILocationInfoResponse[];
  stateInfo: IStateInfoResponse[];
  cityInfo: ICityInfoResponse[];
  currencyInfo: ICurrencyResponse[];
  educationInfo: IEducationDegreeResponse;
  specializationInfo: ISpecializationResponse;
  getSkillInfo: ISkillResponse;
  jobStatusUpdate: IJobStatusUpdateResponse;
  getClientInfo: IClientInfoResponse;
  jdSavedAsTemplate: IJdSavedAsTemplateResponse;
}

const initialState: IHomeModuleProps = {
  industryInfo: { status_message: "", data: [] },
  categoryInfo: { status_message: "", data: [] },
  rolesInfo: { status_message: "", data: [] },
  countryInfo: [],
  stateInfo: [],
  cityInfo: [],
  currencyInfo: [],
  educationInfo: { status_message: "", data: [] },
  specializationInfo: { status_message: "", data: [] },
  getSkillInfo: { status_message: "", data: [] },
  jobStatusUpdate: { status_message: "", data: { id: 0, message: "" } },
  getClientInfo: {
    status: "",
    data: {
      totalClients: 0,
      client: []
    }
  },
  jdSavedAsTemplate: { status_message: "", data:{
    jd_templates_list: [],
    page: 0,
    pageSize: 0,
    total_jd_templates_count: 0
  }},
};

const homeSlice = createSlice({
  name: "homeInfo",
  initialState,
  reducers: {
    setClientInfo: (state, action: PayloadAction<IClientInfoResponse>) =>{
      state.getClientInfo = action.payload;
    },
    setIndustryInfo: (state, action: PayloadAction<IIndustryResponse>) => {
      state.industryInfo = action.payload;
    },
    setCategoryInfo: (state, action: PayloadAction<ICategoryResponse>) => {
      state.categoryInfo = action.payload;
    },
    setRolesInfo: (state, action: PayloadAction<IRolesResponse>) => {
      state.rolesInfo = action.payload;
    },
    setCountryInfo: (state, action: PayloadAction<ILocationInfoResponse[]>) => {
      state.countryInfo = action.payload;
    },
    setStateInfo: (state, action: PayloadAction<IStateInfoResponse[]>) => {
      state.stateInfo = action.payload;
    },
    setCityInfo: (state, action: PayloadAction<ICityInfoResponse[]>) => {
      state.cityInfo = action.payload;
    },
    setCurrencyInfo: (state, action: PayloadAction<ICurrencyResponse[]>) => {
      state.currencyInfo = action.payload;
    },
    setEducationDegreeInfo: (state, action: PayloadAction<IEducationDegreeResponse>) => {
      state.educationInfo = action.payload;
    },
    setSpecializationInfo: (state, action: PayloadAction<ISpecializationResponse>) => {
      state.specializationInfo = action.payload;
    },
    setSkillInfo: (state, action: PayloadAction<ISkillResponse>) => {
      state.getSkillInfo = action.payload;
    },
    setJobStatusUpdate: (state, action: PayloadAction<IJobStatusUpdateResponse>) => {
      state.jobStatusUpdate = action.payload;
    },
    setJdSavedAsTemplate: ( state, action: PayloadAction<IJdSavedAsTemplateResponse>) => {
      state.jdSavedAsTemplate = action.payload;
    }
  },
});

// Export the reducer
export default homeSlice.reducer;

// Export actions
export const {
  setClientInfo, setIndustryInfo,
  setCategoryInfo,
  setRolesInfo,
  setCountryInfo,
  setStateInfo,
  setCityInfo,
  setCurrencyInfo,
  setEducationDegreeInfo,
  setSpecializationInfo,
  setSkillInfo,
  setJobStatusUpdate,
  setJdSavedAsTemplate,
} = homeSlice.actions;

// Selectors
export const ClientData = (state: RootState) => {
  const {totalClients, client} = state.getHomeInfo.getClientInfo.data;
  return {totalClients, client}
}
export const industryData = (state: RootState) => state.getHomeInfo.industryInfo.data;
export const categoryData = (state: RootState) => state.getHomeInfo.categoryInfo.data;
export const rolesData = (state: RootState) => state.getHomeInfo.rolesInfo.data;
export const countryData = (state: RootState) => state.getHomeInfo.countryInfo;
export const stateData = (state: RootState) => state.getHomeInfo.stateInfo;
export const cityData = (state: RootState) => state.getHomeInfo.cityInfo;
export const currencyData = (state: RootState) => state.getHomeInfo.currencyInfo;
export const certifiedDegreeData = (state: RootState) => state.getHomeInfo.educationInfo.data;
export const specializationData = (state: RootState) => state.getHomeInfo.specializationInfo.data;
export const skillData = (state: RootState) => state.getHomeInfo.getSkillInfo.data;
export const jobStatusData = (state: RootState) => state.getHomeInfo.jobStatusUpdate;
export const jdSavedAsTemplateData = (state: RootState) => state.getHomeInfo.jdSavedAsTemplate.data;