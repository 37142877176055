import { useEffect, useState } from "react";
import { Box, List, ListItem, MenuItem } from "@mui/material";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HomeIcon from "@mui/icons-material/Home";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import WorkIcon from "@mui/icons-material/Work";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import { NAMESPACE, getCommonNS } from "../../utils/i18nUtils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  dashboardPath,
  jobsPage,
  resumesPage,
  settingsPage,
} from "../../pages/Routes/routes";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { loggedInUserPermissions } from "../../utils/permissionManagement";
import { isDefined } from "../../utils/helper";

export default function Menubar() {
  const { t } = useTranslation(NAMESPACE.COMMON);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const menuItems = [
    {
      Menu: "HOME",
      Icon: <HomeOutlinedIcon style={{ fill: "var(--color-white)" }} />,
      FilledIcon: <HomeIcon style={{ fill: "var(--color-dakBlue)" }} />,
      path: dashboardPath,
      privilege: "JD_COUNT",
    },
    {
      Menu: "JOBS",
      Icon: <WorkOutlineOutlinedIcon style={{ fill: "var(--color-white)" }} />,
      FilledIcon: <WorkIcon style={{ fill: "var(--color-dakBlue)" }} />,
      path: jobsPage,
      privilege: "VIEW_JOBS",
    },
    {
      Menu: "RESUMES",
      Icon: <PersonSearchOutlinedIcon style={{ fill: "var(--color-white)" }} />,
      FilledIcon: <PersonSearchIcon style={{ fill: "var(--color-dakBlue)" }} />,
      path: resumesPage,
      privilege: "VIEW_RESUMES",
    },
    {
      Menu: "SETTINGS",
      Icon: <SettingsOutlinedIcon style={{ fill: "var(--color-white)" }} />,
      FilledIcon: <SettingsIcon style={{ fill: "var(--color-dakBlue)" }} />,
      path: settingsPage,
      privilege: "JD_COUNT",
    },
  ];

  const [activeMenu, setActiveMenu] = useState("HOME");

  const handleMenuClick = (menu: string) => {
    setActiveMenu(menu);
  };

  useEffect(() => {
    const currentRoute = window.location.pathname;
    const TempCurrentMenu = menuItems.find(
      (menu) => menu.path === currentRoute
    );
    if (TempCurrentMenu !== undefined) {
      setActiveMenu(TempCurrentMenu.Menu);
    } else {
      setActiveMenu("HOME");
    }
  }, []);

  return (
    <div>
      <Box component="nav" className="hire-assisto__menubar__vertical-menu">
        <List className="d-flex flex-column" sx={{ gap: 2.5 }}>
          {menuItems.map((item, key) =>
            isDefined(loggedInUserPermissions) &&
            loggedInUserPermissions.includes(item?.privilege) ? (
              <ListItem
                key={key}
                className={`d-flex flex-center flex-column cursor-pointer `}
                onClick={() => {
                  handleMenuClick(item.Menu);
                  navigate(item.path);
                }}
              >
                <div
                  className={`${
                    activeMenu === item.Menu
                      ? "hire-assisto__menubar__bg-white"
                      : ""
                  }  d-flex flex-center flex-column`}
                >
                  {activeMenu === item.Menu ? item.FilledIcon : item.Icon}
                </div>
                <div
                  className={`${
                    activeMenu === item.Menu
                      ? "hire-assisto__menubar__text-white"
                      : "hire-assisto__menubar__text-white"
                  } hire-assisto__menubar__menuItems`}
                >
                  {t(`COMMON.${item.Menu}`, getCommonNS())}
                </div>
              </ListItem>
            ) : null
          )}
        </List>
      </Box>
    </div>
  );
}
