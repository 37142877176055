import { Box, Paper, Stack, Typography } from "@mui/material";
import { ThemeProvider } from "styled-components";
import theme from "../../widgets/Theme/theme";
import SettingsIcon from "../../assets/images/settings-page.svg";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../utils/i18nUtils";
import AssistoTabs, { TabInfo } from "../../widgets/Tabs";
import Templates from "./templates/templates";

function SettingsPage() {
  const { t } = useTranslation(NAMESPACE.SETTINGS);

  const tabsComponent: TabInfo[] = [
    {
      label: "General Settings",
      content: undefined,
    },
    {
      label: "Interview Process",
      content: undefined,
    },
    {
      label: "Templates",
      content: <Templates/>,
    },
    {
      label: "Job Portal",
      content: undefined,
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <Box width={"98%"} my={4}>
        <Paper>
          <Stack p={4} direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} alignItems={"center"} gap={1.5}>
              <img width={"22%"} src={SettingsIcon} alt="resumesIcon" />
              <Stack direction={"column"} width={"100%"}>
                <Typography variant="h5" fontWeight={"var(--font-weight-500)"}>
                  {t("SETTINGS.SETTINGS")}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Box>

      <Paper sx={{ marginBottom: 4, width: "98%" }}>
        <AssistoTabs tabs={tabsComponent} />
      </Paper>
    </ThemeProvider>
  );
}

export default SettingsPage;
