import React, { useRef } from "react";
import {
  Box,
  Grid,
  Paper,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { ButtonVarient } from "../../../utils/commonEnums";
import { AssistoButton } from "../../../widgets/AssitoButton";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../utils/i18nUtils";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import theme from "../../../widgets/Theme/theme";
import { AssistoTable, Column } from "../../../widgets/Table";
import AssistoModal from "../../../widgets/Modal";
import { CSSProperties, useEffect, useState } from "react";
import { ReactComponent as FolderIcon } from "../../../assets/images/folder.svg";
import { jdSavedAsTemplateData } from "../../../reducers/homeReducer";
import {
  ISavedTemplateData,
  jdSavedAsTemplate,
} from "../../../thunks/homeThunk";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { uploadJdTemplatesThunk } from "../../../thunks/settingsThunk";

const uploadTemplateModalStyle: CSSProperties = {
  position: "absolute",
  transform: "translate(50%, -50%)",
  top: "50%",
  right: "50%",
  width: 800,
  maxHeight: 600,
  borderRadius: "8px",
  backgroundColor: "#fff",
  padding: 3,
};

function Templates() {
  const { t } = useTranslation(NAMESPACE.SETTINGS);
  const TemplateColumns: Column[] = [
    { id: "serialNo", label: "SL NO", minWidth: 50 },
    { id: "templateName", label: "Template Name", minWidth: 150 },
    { id: "createdDate", label: "Created Date", minWidth: 150 },
    // { id: "editIcons", label: "Edit Icons", minWidth: 200 },
  ];

  const [uploadTemplateModal, setUploadTemplateModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleUploadTemplateChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (fileExtension === "zip") {
        setSelectedFile(file);
        if (selectedFile) {
          const formData = new FormData();
          formData.append("file", selectedFile);
          try {
            await dispatch(uploadJdTemplatesThunk({ formData }));
          } catch (error) {
            throw error;
          }
        }
      } else {
        alert("Invalid File");
      }
    }
  };
  const handleTemplateSelect = () => {
    inputRef.current?.click();
  };

  const jdTemplateData: ISavedTemplateData = useSelector(jdSavedAsTemplateData);
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    dispatch(jdSavedAsTemplate);
  }, [dispatch]);
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Paper>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
            p={5}
          >
            <div>
              <Typography variant="h3">
                {t("SETTINGS.TEMPLATES.TEMPLATES_LIST")}
              </Typography>
            </div>
            <div className="d-flex">
              <Stack mr={3}>
                <AssistoButton
                  buttonVarient={ButtonVarient.Contained}
                  name={"Upload Templates"}
                  endIcon={<FileUploadOutlinedIcon />}
                  handleBtnClick={() => setUploadTemplateModal(true)}
                />
              </Stack>

              <Stack mr={4}>
                <AssistoButton
                  buttonVarient={ButtonVarient.Contained}
                  name={"Create Templates"}
                  endIcon={<DescriptionOutlinedIcon />}
                  handleBtnClick={undefined}
                />
              </Stack>
            </div>
          </Stack>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Stack className="hire-assisto__white-bg">
                <AssistoTable
                  rows={jdTemplateData.jd_templates_list.map((templateList) => {
                    const date = new Date(
                      templateList.jd_template_details.created_at
                    );
                    const formattedDate = `${date
                      .getDate()
                      .toString()
                      .padStart(2, "0")}-${(date.getMonth() + 1)
                      .toString()
                      .padStart(2, "0")}-${date.getFullYear()}`;
                    return {
                      serialNo: templateList.jd_template_details.id,
                      templateName:
                        templateList.jd_template_details.template_name,
                      createdDate: formattedDate,
                    };
                  })}
                  columns={TemplateColumns}
                  defaultMessage={t(
                    "SETTINGS.TEMPLATES.NO_DATA_AVAILABLE"
                  )}
                  headerFontSize={"14px"}
                />
              </Stack>
            </Grid>
          </Grid>

          <AssistoModal
            open={uploadTemplateModal}
            handleClose={() => {
              setUploadTemplateModal(false);
            }}
            isCloseButtonNeeded={true}
            title={t("SETTINGS.TEMPLATES.UPLOAD_FILES")}
            style={uploadTemplateModalStyle}
          >
            <Grid
              marginY={"75px"}
              container
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item xl={6} lg={6} md={6} sm={12}>
                <Stack justifyContent={"center"} alignItems={"center"}>
                  <FolderIcon />
                </Stack>
                <Typography color={"var(--color-text-grey)"} p={1.5}>
                  <Stack spacing={0.5}>
                    <span style={{ fontSize: "24px", color: "black" }}>
                      {t("SETTINGS.TEMPLATES.SELECT_FILES_TO_UPLOAD")}
                    </span>
                    <span style={{ fontSize: "12px" }}>
                      {t(
                        "SETTINGS.TEMPLATES.PLEASE_UPLOAD_FILES_IN_THIS_EXTENSION"
                      )}
                    </span>
                  </Stack>
                </Typography>
              </Grid>
            </Grid>
            <Stack justifyContent={"center"} direction={"row"} gap={2}>
              <input
                ref={inputRef}
                type="file"
                style={{ display: "none" }}
                onChange={handleUploadTemplateChange}
              />
              <AssistoButton
                buttonVarient={ButtonVarient.Contained}
                name="Browse"
                handleBtnClick={handleTemplateSelect}
              />
            </Stack>
          </AssistoModal>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}

export default Templates;
