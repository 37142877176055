import HomePage from "../Home";
import Layout from "../../widgets/Layout";
import DashBoard from "../Dashboard";
import ErrorScreen from "../../widgets/ErrorScreen";
import ResumesPage from "../ResumesPage";
import CreateJdPage from "../CreateJdPage";
import JobsPage from "../JobsPage";
import ForbiddenPage from "../../widgets/ErrorScreen/ForbiddenScreen";
import SettingsPage from "../SettingsPage/index"

export const homePath = "/";
export const dashboardPath = "/dashboard";
export const errorScreen = "/error-page";
export const resumesPage = "/resumes-page";
export const createJdPage = "/create-jd";
export const jobsPage = "/jobs-page";
export const forbiddenPage = "/forbidden-page";
export const settingsPage = "/settings-page"

export interface IRouteConfig {
  path: string;
  component: React.ElementType;
  layout?: React.ElementType;
}
export const routesConfig: IRouteConfig[] = [
  {
    path: homePath,
    component: HomePage,
  },
  {
    path: dashboardPath,
    component: DashBoard,
    layout: Layout,
  },
  {
    path: errorScreen,
    component: ErrorScreen,
  },
  {
    path: forbiddenPage,
    component: ForbiddenPage,
  },
  {
    path: resumesPage,
    component: ResumesPage,
    layout: Layout,
  },
  {
    path: createJdPage,
    component: CreateJdPage,
    layout: Layout,
  },
  {
    path: jobsPage,
    component: JobsPage,
    layout: Layout,
  },
  {
    path: settingsPage,
    component: SettingsPage,
    layout: Layout,
  },
];