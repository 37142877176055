import { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import theme from "../Theme/theme";
import { text } from "stream/consumers";
import { ButtonVarient } from "../../utils/commonEnums";

interface IButtonProps {
  buttonVarient: ButtonVarient;
  name: string;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  handleBtnClick?: () => void;
  isDisable?: boolean;
  buttonColor?: string;
  dataTestId?:string;
}

export const AssistoButton = (props: IButtonProps) => {
  const ButtonTheme = styled(Button)({
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    borderRadius: "26px",
    lineHeight: 1.5,
    fontFamily: "Poppins",
    textAlign: "left",

    backgroundColor: props.isDisable
      ? theme.palette.action.disabledBackground
      : undefined,
    color: props.isDisable ? theme.palette.action.disabled : props.buttonColor,
    "&:hover": {
      backgroundColor:
        props.buttonVarient === "text"
          ? "transparent"
          : "var(--color-dark-purple)",
      borderColor: "var(--color-dark-purple)",
      color: props.buttonVarient === "outlined" && theme.palette.common.white,
    },
    "&:focus": {
      backgroundColor:
        props.buttonVarient === "text"
          ? theme.palette.common.white
          : "var(--color-dark-purple)",
      color: props.buttonVarient === "outlined" && theme.palette.common.white,
      borderColor: theme.palette.common.black,
    },
  });

  return (
    <ButtonTheme
      data-testid = {props.dataTestId}
      variant={props.buttonVarient}
      endIcon={props.endIcon}
      startIcon={props.startIcon}
      onClick={props.handleBtnClick && props.handleBtnClick}
      sx={{
        px: props.buttonVarient !== "text" ? 3.5 : 0,
        py: 1.25,
        justifyContent: "left",
      }}
      disabled={props.isDisable}
    >
      {props.name}
    </ButtonTheme>
  );
};
